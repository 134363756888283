@import './fonts';

body {
  background-color: $black;
}

#main {
  background-color: $white;
}

.media-heading {
  font-family: $font-family-sans-serif;
}

.btn {
  text-transform: uppercase;
  font-family: 'AGFont', $font-family-sans-serif;
}

// .btn-primary {
//   &:hover,
//   &:active,
//   &:focus {
// background-color: $gray-100 !important;
// border-color: $gray-100 !important;
// color: $black !important;
//   }
// }

.btn-primary {
  // background, border, hover background, hover border, focus background, focus border
  @include button-variant(
    $black,
    $black,
    // darken($black, 5%),
    // darken($black, 5%),
    // darken($black, 5%),
    // darken($black, 5%)
    $gray-600,
    $gray-600,
    $gray-600,
    $gray-600
  );
  color: $white;
}

.simple-cart {
  .badge-info {
    background-color: $red;
  }
}

h1 {
  //color: $viebs;
  &.leader {
    font-size: 4rem;
  }
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5,
.legend,
legend,
.title,
.nav-link,
.price {
  font-family: 'AGFont', $font-family-sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 0.0125rem;
}

h1, h5,
.product-title,
.legend,
legend,
.title,
.nav-link {
  text-transform: uppercase;
}

// PRODUCTS CONTEXT
// DANGER DAN

body.product-listing-page,
body.ticket-listing-page,
body.ticket-detail-page,
body.product-detail-page,
body.alg-page,
body.graydient-background,
body.dangerdan-page,
body.viebs-page,
body.category-detail-page {
  .navbar-brand {
    //background-color: $agorange !important;
  }
  #main {
    //background: $gray-background-gradient;
    background-color: $white;
  }
  .maki.card {
    background-color: transparent;
    color: $white;
    .title {
      color: $black;
      @include font-size(2.25rem);
    }
    .sub-title {
      font-size: 0.8em;
    }
    &:hover {
      background-color: $white;
      color: $black;
      img {
        background-color: $white;
      }
      .title,
      .price {
        color: $black;
      }
    }
  }
  .product-content {
    background: $white;
    a {
      //color: $white !important;
    }
    a:hover {
      //color: fade-out($gray-500, 0.4) !important;
    }
  }
  .container.detail-page {
    color: $black;
  }
  .carousel-control-next,
  .carousel-control-prev {
    color: $black;
  }
  .carousel-indicators {
    position: relative;
  }
  .product-thumb {
    background-color: transparent;
    height: 60px;
    width: 60px;
  }
  .btn-primary {
    // background, border, hover background, hover border, focus background, focus border
    @include button-variant(
      $black,
      $black,
      // darken($black, 5%),
      // darken($black, 5%),
      // darken($black, 5%),
      // darken($black, 5%)
      $gray-600,
      $gray-600,
      $gray-600,
      $gray-600
    );
    color: $white;
  }
  .shoppingcart-finisher {
    background-color: rgba($gray-700, 0.5);
  }
}

body.viebs-page {
  h1 {
    color: $black;
    small {
      color: $white;
      font-family: 'Antonio';
    }
    @include font-size(5rem);
  }
}

body.graydient-background {
  color: $viebs;
}

body.dangerdan-page {
  h1 {
    small {
      font-family: 'Fraunces', serif;
      color: #fff;
      display: block;
      font-size: 0.7em;
      text-transform: none;
    }
  }
  h3 {
    font-family: 'Antonio' !important;
  }
}

body.alg-page {
  h1 {
    @include font-size(6rem);
    line-height: 1;
    letter-spacing: 1px;
    small {
      color: #fff;
      display: block;
      @include font-size(2.5rem);
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
}

#after-variants .alert {
  margin-bottom: 22.5px + 15px;
}

body.ticket-detail-page {
  .card.article-title {
    background-color: $black;
    border: 0;
    h1 {
      color: $white;
      font-size: $h1-font-size;
      small {
        color: $white;
        font-family: 'AGFont2';
        text-transform: none !important;
      }
    }
    .super-title {
      color: $gray-600;
    }
  }
  .product-info-bar {
    font-family: 'AGFont';
    text-transform: uppercase;
    i {
      color: $viebs !important;
    }
    box-shadow: none;
  }
  // TABS TICKET DETAIL
  article.ticket-detail {
    .nav-tabs {
      border-bottom: 2px solid rgba($color: white, $alpha: 0.1);
    }
    .nav-tabs .nav-link {
      color: $white;
      border: 0;
      border-bottom: 2px solid rgba($color: white, $alpha: 0.1);
    }
    .nav-tabs .nav-link.active {
      background: transparent;
      border-bottom: 2px solid $white;
      &:hover {
        color: $white !important;
        background: rgba($color: white, $alpha: 0.1);
      }
    }
    .nav-tabs .nav-link:focus,
    .nav-tabs .nav-link:hover {
      border-bottom: 2px solid $white;
      background: rgba($color: white, $alpha: 0.1);
      color: $white !important;
    }
    section.detail-page {
      a {
        color: $white;
      }
    }
  }
}

#root {
  padding-top: 0;
}

// .sticky-bottom {
// }

#footer {
  min-height: 20vh;
  //background-color: $gray-100;
  background-color: $black;
  color: $white;
  h5 {
    font-weight: 400 !important;
  }
  a {
    color: $white;
  }
}

// NIGIRI

.nigiri.card {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgba($black, 0.25);
  .nigiri-content {
    padding: 1.25rem 0;
    padding-top: 0;
    margin-top: 0.2rem;
    color: $agyellow;
    @include media-breakpoint-down(md) {
      display: table;
    }
  }
  .nigiri-date {
    font-size: $h5-font-size;
    color: $black;
    font-family: 'AGFont';
  }
  .nigiri-body {
    font-size: $h4-font-size;
    border-left: 0;
    text-transform: uppercase;
    font-weight: bold;
    @include media-breakpoint-down(md) {
      padding-left: 0;
      line-height: 2rem;
    }
  }
  .badge {
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 0.8em;
  }
  .title {
    font-size: $font-size-base * 1.2;
    margin-bottom: 0;
    color: $black;
    font-family: 'AGFont', $font-family-sans-serif !important;
  }
  .subtitle {
    font-size: $font-size-base;
    font-family: 'AGFont2', $font-family-sans-serif !important;
    text-transform: none;
    margin-bottom: 0;
    color: $gray-400;
    font-weight: normal;
    @include media-breakpoint-down(md) {
      line-height: 1rem;
      margin-bottom: 0.5rem;
    }
  }
  .location {
    font-family: 'AGFont', $font-family-sans-serif !important;
    font-size: $h4-font-size !important;
    color: $black;
    @include media-breakpoint-down(md) {
      margin-bottom: 1rem;
    }
  }
  .nigiri-tags * {
    display: none;
  }
  .nigiri-tags .tag-6038 {
    display: block;
    z-index: 2;
    transform: rotate(2.5deg);
    font-size: 12px;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
  }
}

// MAKI

.maki.card {
  text-align: center;
  //transition: background-color 0.1s ease;
  &:hover {
    background-color: $gray-600;
    //transition: all 0.2s ease;
  }
  .card-body {
    padding: $card-spacer-x;
    .card-title {
      width: 100%;
      span.title {
        font-weight: 400;
        text-align: center;
        font-size: $h4-font-size;
        //font-family: 'Visual-Regular', $font-family-sans-serif;
        text-transform: uppercase;
        max-width: 100%;
        @extend .text-truncate;
      }
      span.sub-title {
        font-size: 1em;
        margin-bottom: 8px;
        @extend .text-truncate;
        color: $gray-600;
        font-family: 'AGFont2', $font-family-sans-serif;
      }
      span.price {
        font-size: 1rem;
        color: $black;
      }
    }
  }
}

// MENU & NAVBAR

#main-menu {
  background: transparent;

  .nav-link {
    background-color: rgba($black, 0.9); //$light-milk;
    color: $white !important;
    margin-bottom: 0.4rem;
    font-weight: 900;
    text-transform: uppercase;
  }
}

.secondary-menu .menu {
  display: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

#header > .navbar {
  height: $calculated-navbar-height-sm;
  //border-top: 5px solid $agorange;
  border-top: 5px solid white;
  // background: linear-gradient(170deg, rgba(0, 0, 0, 1) 0%, rgba(28, 28, 28, 1) 100%) fixed;
  background: $black !important;
  i {
    font-weight: 300 !important;
  }
  //box-shadow: 2px 2px 2px rgb(0 0 0 / 30%);
  font-size: 16px;
  @include media-breakpoint-up(md) {
    height: $calculated-navbar-height;
    font-size: 20px;
    font-weight: bold;
    padding-left: 2vw;
    padding-right: 2vw;
  }

  .navbar-brand-container {
    position: absolute;
    top: -8px;
    box-shadow: 0.1rem 0.1rem 0.75rem 0.1rem rgb(0 0 0 / 30%);
    @include media-breakpoint-down(sm) {
      left: 2vw;
    }
    transform: rotate(-2deg);
    transition: transform 0.5 ease;
    // &:hover {
    //   transform: rotate(-2deg);
    // }
  }
  .navbar-brand {
    padding: 15px 15px;
    //background-color: $agorange;
    background-color: white !important;
    background-image: url('./assets/images/logo24.png');
    background-position: center;
    background-repeat: no-repeat;

    img {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      background-size: 100%;
      height: 4rem;
      width: 4rem;
    }
    @include media-breakpoint-up(md) {
      background-size: 100%;
      height: 8rem;
      width: 8rem;
    }
  }

  .nav-link {
    &.active {
      color: $gray-500;
    }
    color: $white;
    @include media-breakpoint-up(md) {
      font-size: 1.3rem;
    }
  }
  .badge {
    margin-top: -4px;
    font-family: $font-family-base;
  }
}

.offcanvas-collapse {
  top: $calculated-navbar-height + 1rem;
  @include media-breakpoint-down(sm) {
    top: $calculated-navbar-height-sm + 1rem;
  }
}

#cart-menu,
#main-menu {
  font-size: 16px;
}

#main-menu {
  bottom: auto;
  overflow: visible;
}

.product-detail-title {
  font-weight: 400;
  font-style: normal;
  //font-size: 2rem;
  @include media-breakpoint-up(md) {
    //font-size: 55px;
  }
  color: $black;
  position: relative;
  margin-top: 0;
  margin-bottom: map-get($spacers, 4);

  small {
    font-family: AGFont2, $font-family-sans-serif;
    text-transform: none;
    font-size: 1.25rem;
    color: $gray-600;
    margin-top: 0.5rem;
  }
}

#product-listing {
  // 3635 = "sale" tag
  .maki .tag {
    display: none;
  }
  .maki .tag-3635 {
    @extend .rounded-circle;
    color: transparent;
    padding: 0;
    width: 65px;
    height: 65px;
    display: block;
    background: transparent url('./assets/images/salesalesale.svg') no-repeat center center;
    position: absolute;
    z-index: 1;
    left: 65%;
    bottom: 35%;
    top: auto;
    text-indent: -99999px;
  }
  .maki .tag-5235 {
    @extend .rounded-circle;
    color: transparent;
    padding: 0;
    width: 65px;
    height: 65px;
    display: block;
    background: transparent url('./assets/images/wiederda.svg') no-repeat center center;
    position: absolute;
    z-index: 1;
    left: 65%;
    bottom: 35%;
    top: auto;
    text-indent: -99999px;
  }
}

.g8-page-content {
  color: $white;
  background-color: black;
  .g8-logo {
    filter: invert(1);
  }
}

// QF for
.radio-list-group.packagemodalitem {
  h5 {
    //font-family: $font-family-sans-serif !important;
    font-size: 1em;
  }
}

// TICKET DETAIL TABS

// a.ticket-detail-tab {
//   background-color: transparent;
//   color: $gray-700;
//   border-color: transparent;
//   &:hover {
//     border-color: transparent;
//   }
// }

// STYLING PRODUCT-CONFIG

body.product-detail-page {
  .modal-dialog {
    @include media-breakpoint-up(sm) {
      max-width: 600px;
    }
  }
  .radio-list-group {
    box-shadow: none !important;
  }
  .kachel-header {
    background-color: $white;
    border-color: transparent;
    &.active {
      border-color: $agorange;
    }
    .media {
      justify-content: center !important;
      flex-direction: column;
      .product-thumb {
        margin-right: 0 !important;
        @extend .mb-2;
        width: 50%;
        height: auto;
        border-radius: 0;
      }
    }
    h5 {
      font-size: 1.2rem !important;
    }
    .custom-radio {
      display: none;
    }
  }
  .kachel-container {
    display: flex;
    padding-bottom: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .custom-radio {
      display: none;
    }
    .kachel {
      img {
        display: none;
      }
      background-color: transparent;
      display: flex;
      h5 {
        font-size: 1.2rem !important;
      }
      justify-content: center;
      height: 4rem;
      flex: 1 1 20%;
      //margin: 0.25rem 0;
      &:hover {
        background-color: rgba($gray-200, 0.5);
      }
      &.active {
        background-color: $agorange;
        border-color: $agorange;
        color: $black;
        font-weight: 700;
      }
    }
  }
}

// PFUSCH AM BAU

.navbar .hamburger-box {
  margin-top: -3px;
}

.navbar .hamburger-inner,
.navbar .hamburger-inner:after,
.navbar .hamburger-inner:before {
  height: 1.5px;
  background-color: $white;
}
.navbar .hamburger.is-active .hamburger-inner,
.navbar .hamburger.is-active .hamburger-inner:after,
.navbar .hamburger.is-active .hamburger-inner:before {
  background-color: $white;
}

.text-alg {
  color: $agorange;
  font-weight: 700 !important;
}

// fix for add to cart button scrolled out of sight on iPhones.
// spinner is hidden so that add to cart button is aligned.

@include media-breakpoint-only(xs) {
  table.variant-listing {
    display: grid;
    grid-template-columns: auto;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }

    thead {
      display: none;
    }

    tbody,
    tr {
      display: inherit;
      margin-bottom: 1rem;
      background-color: $white !important;
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    tr {
      grid-template-rows: auto;
      box-shadow: $box-shadow;
      //border: 1px solid $gray-200;
      th {
        padding-bottom: 0.1rem;
      }
      td:first-of-type {
        border-top: 0;
        padding-top: 0.1rem;
      }
    }

    tbody tr td,
    tbody tr th {
      text-align: left !important;
    }

    fieldset.number-input {
      button,
      input {
        width: stretch;
        width: -webkit-fill-available;
        width: fill-available;
      }
    }

    tbody {
      background-color: $black !important;
    }

    colgroup {
      display: none;
    }

    div.invisible.spinner-border {
      display: none !important;
    }

    button.add-product {
      width: 100%;
      max-width: 100%;
      span {
        display: inline !important;
      }
    }
  }
}

// cart-option-item

.cart-option-item {
  .variant-chooser {
    h5 {
      font-family: $font-family-base;
      font-size: $h6-font-size;
    }
  }
}

// btn-tickets

.btn-tickets {
  display: grid;
  align-items: center;
  color: $gray-400;
  border-color: $gray-400;
  &.active {
    border-color: $black !important;
  }
  &:hover {
    border-color: $gray-400 !important;
    background-color: $gray-400;
    // color: $gray-600;
  }
  small {
    display: block;
    //color: $black;
  }
}

// sold out checkbox filter

.filter-checkbox-label {
  display: inline;
  color: $white;
}
//#soldOut:checked {
//}

.filter-soldout-wrapper {
  background-color: $white;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

.cart-listing {
  background-color: $white;
}

// HIGHLIGHTS PAGE

// .highlights-page {
//   .image-kacheln {
//      max-width: 2000px;
//   }
// }

// for 5 listings-buttons
.listing-buttons-container .col {
  flex: 0 0 33, 33333333333%;

  @include media-breakpoint-down(md) {
    flex: 0 0 100%;
  }
}

body.amrw {
  h2, h3 {
    font-family: 'AGFont2', $font-family-sans-serif !important;
  }
  h3 {
    font-size: 1.75rem;
    @include media-breakpoint-down(md) {
      font-size: 1.25rem !important;
      margin-top: 1rem;
    }
  }
  #main {
  background: url('./assets/images/tape1.png'), url('./assets/images/tape2.png'), $white;
  background-position: left bottom, top right;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 15vw, 6vw;
  @include media-breakpoint-down(md) {
    background-size: 30vw, 10vw;
  }
  }
}
